import React, { useState, useEffect } from "react";

import { MainProvider } from "../../contexts/MainContext";
import generateUuId from "../../utils/generateUuId";
import useGtmPageChange from "../../hooks/useGtmPageChange";
import useWindowSize from "../../hooks/useWindowSize";
import { GrowsumoType } from "@types";
import HeaderWithCtaOnly from "../../components/Headers/HeaderWithCtaOnly";

import "../main.scss";
import FooterNavigationEssential from "@containers/builderSectionModels/FooternavigationEssential";

declare global {
  interface Window {
    mixpanelTrialSent: boolean;
    trackMixpanelEvent: (
      event: string,
      data?: {
        [key: string]: any;
      }
    ) => void;
    grecaptcha: any;
    dataLayer: {
      push: (someObj: Object) => boolean;
    };
    gtag: any;
    fbq: any;
    google_conversion_first_name: string;
    google_conversion_last_name: string;
    google_conversion_email: string;
    google_conversion_phone_number: string;
    Grnhse: {
      Iframe: {
        load: (jobId?: number) => void;
      };
    };
    growsumo?: GrowsumoType;
    Osano: {
      cm: {
        showDrawer: (s: string) => void;
      };
    };
  }
}

const GeneralPpcLayout: React.FC<Layouts.GeneralPpcLayout> = ({
  children,
  globalFooterLargeH,
  pathname,
}) => {
  const uuid = generateUuId();
  const { width } = useWindowSize();
  const [isStickyHeader, setIsStickyHeader] = useState<boolean>(false);

  useEffect(() => {
    if (width <= 500) {
      setIsStickyHeader(true);
    } else {
      setIsStickyHeader(false);
    }
  }, [width]);

  useEffect(() => {
    setIsClient(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useGtmPageChange(uuid);

  const [isClient, setIsClient] = useState(false);

  return (
    <MainProvider>
      <React.Fragment key={`layout-${isClient}`}>
        <HeaderWithCtaOnly
          ctaLink={globalFooterLargeH?.navCtaLink!}
          ctaText={globalFooterLargeH?.navCtaText!}
          data-testid="header-with-cta-only"
          noSticky={isStickyHeader}
        />

        <div>
          <main>{children}</main>
        </div>

        <FooterNavigationEssential pathname={pathname} />
      </React.Fragment>
    </MainProvider>
  );
};

export default GeneralPpcLayout;
