import React from "react";
import Slider from "react-slick";

import { IPricingV2 } from "@types";
import ReviewsSliderCard from "../../WhatWeDeliver/ReviewsSlider/ReviewsSliderCard";

import styles from "./PricingSlider.module.scss";
import "./PricingSlider.scss";

type ArrowPropsType = {
  isPrevious?: boolean;
  className?: string;
  isWhite?: boolean;
  onClick?: () => void;
};

type PricingSliderPropsType = {
  sliderList: IPricingV2.SliderListNode[];
  className?: string;
  isWhite?: boolean;
};

const PricingSlider: React.FC<PricingSliderPropsType> = ({
  sliderList,
  className,
  isWhite,
}) => {
  const pricingCards =
    sliderList &&
    sliderList.map((card, index) => (
      <ReviewsSliderCard
        cardStyle={styles.pricingReviewCard}
        key={index}
        card={card}
      />
    ));

  const Arrow = ({
    onClick,
    className = "",
    isPrevious = false,
    isWhite,
  }: ArrowPropsType) => {
    const color = isWhite ? styles.white : "";

    const buttonClasses = isPrevious
      ? `${styles.arrow} ${styles.prev} ${color} ${className}`
      : `${styles.arrow} ${styles.next} ${color} ${className}`;

    return (
      <button className={buttonClasses} onClick={onClick}>
        {isPrevious ? "Previous" : "Next"}
      </button>
    );
  };

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "50px",
    draggable: true,
    variableWidth: true,
    speed: 500,
    arrows: true,
    nextArrow: <Arrow isWhite={isWhite} />,
    prevArrow: <Arrow isWhite={isWhite} isPrevious />,
  };

  return (
    <div data-testid="pricing-slider">
      <Slider
        className={`${styles.sliderContainer} ${className}`}
        {...settings}
      >
        {pricingCards}
      </Slider>
    </div>
  );
};

export default PricingSlider;
