import React, { useEffect, useState } from "react";
import usePageOffset from "../../../hooks/usePageOffset";

import styles from "./StickBottomContent.module.scss";

type StickBottomContentType = {
  children: React.ReactNode;
  className?: string;
  mb?: number;
};

export const StickBottomContent: React.FC<StickBottomContentType> = ({
  children,
  className,
  mb = 40,
}) => {
  const { y } = usePageOffset();
  const [activeStyles, setActiveStyles] = useState<string>("");

  useEffect(() => {
    if (y >= 400 && y <= 2750) {
      setActiveStyles(styles.active);
    } else {
      setActiveStyles("");
    }
  }, [y]);

  return (
    <section
      className={`${styles.stickBottomContent} ${activeStyles} ${className}`}
    >
      <div
        className={`full-width w--704`}
        style={{
          marginBottom: mb,
        }}
      >
        {children}
      </div>
    </section>
  );
};

export default StickBottomContent;
