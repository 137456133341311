import * as React from "react";

const ReviewStarYellowLargePartial: React.FC = () => (
  <svg
    data-testid="svg:ReviewStarYellowLargePartial"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.7"
      d="M10.947 10.5168C10.947 10.5168 4.89241 11.1874 0.853486 11.6357C0.489447 11.6798 0.167559 11.9231 0.0468511 12.2929C-0.0738568 12.6627 0.0468511 13.0478 0.315091 13.2912C3.31554 16.0291 7.82389 20.1294 7.82389 20.1294C7.82389 20.1294 6.58615 26.0939 5.76611 30.0734C5.69713 30.4336 5.82742 30.8149 6.14164 31.0429C6.45395 31.2709 6.85631 31.2747 7.17245 31.0965C10.7055 29.0886 15.9975 26.069 15.9975 26.069C15.9975 26.069 21.2914 29.0886 24.8188 31.0984C25.1407 31.2747 25.543 31.2709 25.8553 31.0429C26.1696 30.8149 26.2999 30.4336 26.229 30.0753C25.4089 26.0939 24.1769 20.1294 24.1769 20.1294C24.1769 20.1294 28.6853 16.0291 31.6857 13.2969C31.954 13.0459 32.0728 12.6608 31.954 12.2929C31.8352 11.9251 31.5133 11.6817 31.1493 11.6396C27.1103 11.1874 21.0539 10.5168 21.0539 10.5168C21.0539 10.5168 18.5439 4.96423 16.8712 1.26252C16.7141 0.931055 16.3846 0.699219 15.9975 0.699219C15.6105 0.699219 15.279 0.932971 15.1296 1.26252C13.455 4.96423 10.947 10.5168 10.947 10.5168Z"
      fill="white"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H23.3501L13.5342 32H0V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M10.947 10.5168C10.947 10.5168 4.89241 11.1874 0.853486 11.6357C0.489447 11.6798 0.167559 11.9231 0.0468511 12.2929C-0.0738568 12.6627 0.0468511 13.0478 0.315091 13.2912C3.31554 16.0291 7.82389 20.1294 7.82389 20.1294C7.82389 20.1294 6.58615 26.0939 5.76611 30.0734C5.69713 30.4336 5.82742 30.8149 6.14164 31.0429C6.45395 31.2709 6.85631 31.2747 7.17245 31.0965C10.7055 29.0886 15.9975 26.069 15.9975 26.069C15.9975 26.069 21.2914 29.0886 24.8188 31.0984C25.1407 31.2747 25.543 31.2709 25.8553 31.0429C26.1696 30.8149 26.2999 30.4336 26.229 30.0753C25.4089 26.0939 24.1769 20.1294 24.1769 20.1294C24.1769 20.1294 28.6853 16.0291 31.6857 13.2969C31.954 13.0459 32.0728 12.6608 31.954 12.2929C31.8352 11.9251 31.5133 11.6817 31.1493 11.6396C27.1103 11.1874 21.0539 10.5168 21.0539 10.5168C21.0539 10.5168 18.5439 4.96423 16.8712 1.26252C16.7141 0.931055 16.3846 0.699219 15.9975 0.699219C15.6105 0.699219 15.279 0.932971 15.1296 1.26252C13.455 4.96423 10.947 10.5168 10.947 10.5168Z"
        fill="#FFC117"
      />
    </g>
  </svg>
);

export default ReviewStarYellowLargePartial;
