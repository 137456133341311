import * as React from "react";
import { SliderListType } from "@types";
import ReviewStars from "../../../Reviews/ReviewStars";
import { Size } from "../../../../assets/Enums/Size.enum";

import styles from "../ReviewsSlider.module.scss";

type ReviewsSliderCardPropsType = {
  card: SliderListType;
  cardStyle: StyleSheet;
};

const ReviewsSliderCard: React.FunctionComponent<ReviewsSliderCardPropsType> =
  ({ card, cardStyle }) => (
    <div data-testid="reviews-card" className={`reviewCards ${cardStyle}`}>
      <div data-testid="card-header" className={styles.cardHeader}>
        <svg
          className="icon"
          data-testid="card-quotes"
          width="31"
          height="24"
          viewBox="0 0 31 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 21.7116V14.8465C0 13.507 0.381513 12.186 1.14454 10.8837L7.24875 0H11.7179L6.59473 12H9.48333C10.319 12 10.9004 12.186 11.2274 12.5581C11.5544 12.893 11.7179 13.4698 11.7179 14.2884V21.7116C11.7179 22.5674 11.5544 23.1628 11.2274 23.4977C10.9004 23.8326 10.319 24 9.48333 24H2.23458C1.43522 24 0.853863 23.8326 0.490517 23.4977C0.163506 23.1628 0 22.5674 0 21.7116ZM18.4216 21.7116V14.8465C18.4216 13.507 18.8031 12.186 19.5662 10.8837L25.6704 0H30.1395L25.0164 12H27.905C28.7407 12 29.322 12.186 29.649 12.5581C29.976 12.893 30.1395 13.4698 30.1395 14.2884V21.7116C30.1395 22.5674 29.976 23.1628 29.649 23.4977C29.322 23.8326 28.7407 24 27.905 24H20.6562C19.8568 24 19.2755 23.8326 18.9121 23.4977C18.5851 23.1628 18.4216 22.5674 18.4216 21.7116Z"
            fill="#1371C8"
          ></path>
        </svg>
      </div>
      <p data-testid="card-quote" className={styles.cardQuote}>
        {card.quote}
      </p>
      <div>
        <hr data-testid="card-divider" className={styles.reviewsDivider} />
        <div data-testid="card-author" className={styles.reviewsAuthor}>
          <div className={styles.cardBottom}>
            <p className={styles.authorName}>{card.name}</p>
            <p className={styles.authorLocation}>{card.location}</p>
          </div>
          <div data-testid="card-review-stars">
            <ReviewStars size={Size.SMALL} link="/reviews" />
          </div>
        </div>
      </div>
    </div>
  );

export default ReviewsSliderCard;
