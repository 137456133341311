import React from "react";

import PricingSlider from "../Sliders/PricingSlider/PricingSlider";
import { SliderType } from "@types";

import styles from "./GeneralPpcReviewSlider.module.scss";

type GeneralPpcReviewSliderType = {
  slider: SliderType;
};

const GeneralPpcReviewSlider: React.FC<GeneralPpcReviewSliderType> = ({
  slider: { sliderList, sliderSubtitle, sliderTitle },
}) => {
  return (
    <section className={`pos--relative ${styles.gPpcReviewSliderSection}`}>
      <div className={styles.sectionOverlay} />

      <div className={`full-width w--704 ${styles.gPpcReviewSliderCopy}`}>
        <h3 data-testid="slider-title" className={styles.sliderTitle}>
          {sliderTitle}
        </h3>
        <p data-testid="slider-subcopy">{sliderSubtitle}</p>
      </div>

      <div className={styles.sliderWrapper}>
        <PricingSlider isWhite sliderList={sliderList} />
      </div>
    </section>
  );
};

export default GeneralPpcReviewSlider;
