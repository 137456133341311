import React from "react";

import BlackCheckmark from "../../SVGIcons/BlackCheckmark/BlackCheckmark";

import styles from "./HeroCcText.module.scss";

type HeroCcTextType = {
  heroCcText: string;
  className?: string;
};

export const HeroCcText: React.FC<HeroCcTextType> = ({
  heroCcText,
  className,
}) => (
  <p className={`${styles.heroCcText} ${className}`}>
    <BlackCheckmark />
    <span
      data-testid="hero-cc-text"
      dangerouslySetInnerHTML={{ __html: heroCcText }}
    />
  </p>
);

export default HeroCcText;
