import React from "react";
import { MainHeroType } from "@types";

import styles from "./HeroBenefitsSection.module.scss";

type HeroBenefitsSectionType = {
  heroBenefits: MainHeroType["heroBenefits"];
  className?: string;
};

const HeroBenefitsSection: React.FC<HeroBenefitsSectionType> = ({
  heroBenefits,
  className,
}) => {
  const benefitsList =
    heroBenefits &&
    heroBenefits.map(({ benefit }) => (
      <li key={benefit}>
        <span>{benefit}</span>
      </li>
    ));

  return (
    <ul
      data-testid="hero-benefits-list"
      className={`${styles.heroBenefits} ${className}`}
    >
      {benefitsList}
    </ul>
  );
};

export default HeroBenefitsSection;
