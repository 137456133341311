import React from "react";

import GeneralPpcReviewSlider from "../../components/GeneralPpcReviewSlider";
import GeneralPpcBottomCta from "../../components/GeneralPpcBottomCta";
import { GeneralPpcBottomCtaType, SliderType } from "@types";

import styles from "./GeneralPpcSliderAndCtaContainer.module.scss";
import BackgroundImage from "gatsby-background-image";

type GeneralPpcSliderCtaContainerType = {
  slider: SliderType;
  generalPpcBottomCta: GeneralPpcBottomCtaType;
};

const GeneralPpcSliderAndCtaContainer: React.FC<GeneralPpcSliderCtaContainerType> =
  ({ slider, generalPpcBottomCta }) => {
    const imageData =
      generalPpcBottomCta.ctaSectionBg.localFile.childImageSharp.fluid;

    return (
      <section className="pos--relative">
        <GeneralPpcReviewSlider slider={slider} />

        <GeneralPpcBottomCta ctaContent={generalPpcBottomCta} />

        <BackgroundImage
          className={styles.bgOverlay}
          Tag="section"
          fluid={imageData}
          data-id="bg"
        ></BackgroundImage>
      </section>
    );
  };

export default GeneralPpcSliderAndCtaContainer;
