import * as React from "react";

const ReviewStarYellowPartial: React.FC = () => (
  <svg
    data-testid={`svg:ReviewStarYellowPartial`}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2772 17.2697L17.4472 20.3897C17.8272 20.6197 18.2972 20.2797 18.1972 19.8497L16.8272 13.9697L21.3872 10.0197C21.7172 9.72973 21.5472 9.17973 21.0972 9.13973L15.0872 8.62973L12.7372 3.08973C12.5672 2.67973 11.9872 2.67973 11.8172 3.08973L9.46715 8.62973L3.45716 9.13973C3.01716 9.17973 2.83716 9.72973 3.17716 10.0197L7.73716 13.9697L6.36716 19.8497C6.26716 20.2797 6.73716 20.6197 7.11716 20.3897L12.2772 17.2697Z"
      fill="#F2F2F2"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="2"
      width="18"
      height="20"
    >
      <path d="M3 2H20.5L7 22H3V2Z" fill="#BA0DE5" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M12.2772 17.2697L17.4472 20.3897C17.8272 20.6197 18.2972 20.2797 18.1972 19.8497L16.8272 13.9697L21.3872 10.0197C21.7172 9.72973 21.5472 9.17973 21.0972 9.13973L15.0872 8.62973L12.7372 3.08973C12.5672 2.67973 11.9872 2.67973 11.8172 3.08973L9.46715 8.62973L3.45716 9.13973C3.01716 9.17973 2.83716 9.72973 3.17716 10.0197L7.73716 13.9697L6.36716 19.8497C6.26716 20.2797 6.73716 20.6197 7.11716 20.3897L12.2772 17.2697Z"
        fill="#FFC117"
      />
    </g>
  </svg>
);

export default ReviewStarYellowPartial;
