import React from "react";
/* LINK https://www.npmjs.com/package/typewriter-effect */
import Typewriter from "typewriter-effect";

import { MainHeroType } from "@types";

import styles from "./HeroTitleTypewriter.module.scss";

export type HeroTitleTypewriterType = {
  heroTittleTypewriter: MainHeroType["heroTittleTypewriter"];
  className?: string;
};

export const HeroTitleTypewriter: React.FC<HeroTitleTypewriterType> = ({
  heroTittleTypewriter,
  className,
}) => {
  const typewriterItems = heroTittleTypewriter.typewriterItems.map(
    item => item.feature
  );

  return (
    <h1
      className={`${styles.heroTitleTypewriter} ${className}`}
      data-testid="hero-title-typewriter"
    >
      <span
        dangerouslySetInnerHTML={{
          __html: heroTittleTypewriter.heroTitle,
        }}
      />{" "}
      <br className={styles.typewriterBr} />
      <Typewriter
        options={{
          strings: typewriterItems,
          autoStart: true,
          loop: true,
          delay: 100,
          deleteSpeed: 90,
        }}
      />
    </h1>
  );
};

export default HeroTitleTypewriter;
