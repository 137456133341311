import React from "react";

import styles from "./MainHero.module.scss";

export type HeroWrapperType = {
  className?: string;
  children: React.ReactNode;
  [key: string]: any;
};

export const HeroWrapper: React.FC<HeroWrapperType> = ({
  className,
  children,
}) => {
  return (
    <section className={`full-width w--704 ${styles.hero} ${className}`}>
      <div className={styles.heroGrid} data-testid="hero-wrapper">
        {children}
      </div>
    </section>
  );
};

export default HeroWrapper;
