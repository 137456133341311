import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { MainHeroType } from "@types";

import styles from "./HeroImageSection.module.scss";

type HeroImageSectionType = {
  image: MainHeroType["heroImage"];
  className?: string;
  [key: string]: any;
};

export const HeroImageSection: React.FC<HeroImageSectionType> = ({
  image: { altText, localFile },
  className,
  ...rest
}) => {
  const imageData = getImage(localFile);

  return (
    <GatsbyImage
      alt={altText}
      image={imageData}
      className={`${styles.heroImage} ${className}`}
      data-testid="hero-image"
      {...rest}
    />
  );
};

export default HeroImageSection;
