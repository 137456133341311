import React from "react";

import PrimaryButtonWithArrow from "../Buttons/PrimaryButtonWithArrow/PrimaryButtonWithArrow";
import { GeneralPpcBottomCtaType } from "@types";

import styles from "./GeneralPpcBottomCta.module.scss";

type GeneralPpcBottomCtaPropType = {
  ctaContent: GeneralPpcBottomCtaType;
};

const GeneralPpcBottomCta: React.FC<GeneralPpcBottomCtaPropType> = ({
  ctaContent,
}) => {
  const { ctaCopy, ctaLink, ctaText, ctaSubcopy } = ctaContent;

  return (
    <div className={styles.generalPpcCta}>
      <div className="full-width">
        <h2 className={styles.copy}>{ctaCopy}</h2>
        <p className={styles.subcopy}>{ctaSubcopy}</p>

        <PrimaryButtonWithArrow buttonText={ctaText} buttonLink={ctaLink} />
      </div>
    </div>
  );
};

export default GeneralPpcBottomCta;
