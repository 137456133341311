import { useState, useEffect } from "react";
interface Types {
  isChrome: boolean;
  isIExplorer: boolean;
  isFirefox: boolean;
  isSafari: boolean;
  isOpera: boolean;
}

export default function useBrowser(): Types {
  const [browsers, setBrowsers] = useState<Types>({
    isChrome: false,
    isFirefox: false,
    isIExplorer: false,
    isOpera: false,
    isSafari: false,
  });

  useEffect(() => {
    function handleBrowser() {
      const userAgentString = navigator?.userAgent.toLowerCase();

      // Detect Chrome
      let isChrome = userAgentString.indexOf("chrome") > -1;

      // Detect Internet Explorer
      let isIExplorer =
        userAgentString.indexOf("msie") > -1 ||
        userAgentString.indexOf("rv:") > -1;

      // Detect Firefox
      let isFirefox = userAgentString.indexOf("firefox") > -1;

      // Detect Safari
      let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      // Detect Opera
      let isOpera = userAgentString.indexOf("op") > -1;
      // Discard Chrome since it also matches Opera
      if (isChrome && isOpera) isOpera = false;

      setBrowsers({
        isSafari,
        isChrome,
        isIExplorer,
        isFirefox,
        isOpera,
      });
    }

    if (typeof navigator !== "undefined") {
      handleBrowser();
    }
  }, []);
  return browsers;
}
