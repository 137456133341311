import React from "react";

import { HeroAnimationDataType } from "@types";

import styles from "./HeroAnimationSection.module.scss";

type HeroAnimationSectionType = {
  animation: HeroAnimationDataType;
  className?: string;
};

const HeroAnimationSection: React.FC<HeroAnimationSectionType> = ({
  animation,
  className,
}) => {
  const { type, src, animationPoster } = animation;
  return (
    <div
      data-testid="hero-animation-section"
      className={`${styles.heroAnimationVideo} ${className}`}
    >
      <video
        data-testid="hero-animation-video"
        width="100%"
        autoPlay
        playsInline
        muted
        loop
        poster={animationPoster?.mediaItemUrl}
        className="video"
      >
        <source data-testid="hero-animation-source" src={src} type={type} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default HeroAnimationSection;
