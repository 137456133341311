import * as React from "react";
import { Link } from "gatsby";
import ReviewStarYellow from "../SVGIcons/ReviewStars/ReviewStarYellow";
import ReviewStarYellowPartial from "../SVGIcons/ReviewStars/ReviewStarYellowPartial";
import ReviewStarYellowLarge from "../SVGIcons/ReviewStars/ReviewStarYellowLarge";
import ReviewStarYellowLargePartial from "../SVGIcons/ReviewStars/ReviewStarYellowLargePartial";
import { Size } from "../../assets/Enums/Size.enum";

import styles from "./ReviewStars.module.scss";

const ReviewStars: React.FC<{
  size: Size;
  style?: React.CSSProperties;
  link?: string;
  className?: string;
}> = ({ size, link = "#/", className }) => {
  const star = index =>
    size === Size.LARGE
      ? {
          full: <ReviewStarYellowLarge key={index} index={index} />,
          partial: <ReviewStarYellowLargePartial />,
        }
      : {
          full: <ReviewStarYellow key={index} index={index} />,
          partial: <ReviewStarYellowPartial />,
        };

  const stars = [...Array(5)].map((_: null, index) => (
    <Link
      className={className}
      data-testid={`chuchu-${index}`}
      key={index}
      to={link}
    >
      <span
        data-testid={`span:ReviewStars:${index}`}
        className={styles.reviewIcon}
      >
        {index < 4 ? star(index).full : star(index).partial}
      </span>
    </Link>
  ));

  return <>{stars}</>;
};

export default ReviewStars;
