import * as React from "react";

const ReviewStarYellow: React.FC<{ index: number }> = ({ index }) => (
  <svg
    data-testid={`svg:ReviewStarYellow:${index}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="#ffc117"
    width="24"
    height="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 17.27l5.17 3.12c.38.23.85-.11.75-.54l-1.37-5.88 4.56-3.95c.33-.29.16-.84-.29-.88l-6.01-.51-2.35-5.54a.498.498 0 00-.92 0L9.19 8.63l-6.01.51a.5.5 0 00-.28.88l4.56 3.95-1.37 5.88c-.1.43.37.77.75.54L12 17.27z" />
  </svg>
);

export default ReviewStarYellow;
