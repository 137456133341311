import React, { useState, useLayoutEffect } from "react";

import useBrowser from "../../hooks/useBrowser";
import { MainHeroType, HeroAnimationDataType } from "@types";
import HeroAnimationSection from "../../components/shared/HeroAnimationSection";
import HeroBenefitsSection from "../../components/shared/HeroBenefitsSection";
import HeroCcText from "../../components/shared/HeroCcText";
import HeroFormSection from "../../components/shared/HeroFormSection";
import HeroImageSection from "../../components/shared/HeroImageSection";
import HeroTitleTypewriter from "../../components/shared/HeroTitleTypewriter";
import HeroWrapper from "../../components/shared/HeroWrapper";
import ReviewsHomeAB from "../../components/ReviewsHomeAB";

import styles from "./MainHeroContainer.module.scss";

export type MainHeroContainerType = {
  heroData: MainHeroType;
  withTypewriterAnimation?: boolean;
  withBenefitsList?: boolean;
  withAnimation?: boolean;
};

const MainHeroContainer: React.FC<MainHeroContainerType> = ({
  heroData,
  withTypewriterAnimation,
  withBenefitsList,
  withAnimation,
}) => {
  const {
    heroAnimation,
    heroBenefits,
    heroCcText,
    heroCtaLink,
    heroCtaText,
    heroEmailPlaceholder,
    heroImage,
    heroSubcopy,
    heroTitle,
    heroTittleTypewriter,
  } = heroData;

  const { isChrome } = useBrowser();
  const [heroAnimationSrc, setHeroAnimationSrc] =
    useState<HeroAnimationDataType>({
      animationPoster: heroAnimation?.animationPoster,
      src: heroAnimation?.animationWebm.mediaItemUrl,
      type: "video/webm",
    });

  useLayoutEffect(() => {
    if (!isChrome) {
      setHeroAnimationSrc(prevState => ({
        ...prevState,
        src: heroAnimation?.animationMp4.mediaItemUrl,
        type: "video/mp4",
      }));
    }
  }, [isChrome, heroAnimation?.animationMp4]);

  const heroHeader = withTypewriterAnimation ? (
    <HeroTitleTypewriter heroTittleTypewriter={heroTittleTypewriter} />
  ) : (
    <h1 className={styles.heroTitle} data-testid="hero-title">
      {heroTitle}
    </h1>
  );

  const heroAsset = withAnimation ? (
    <HeroAnimationSection animation={heroAnimationSrc} />
  ) : (
    <HeroImageSection image={heroImage} />
  );

  return (
    <HeroWrapper>
      <div className={styles.heroCopyContainer}>
        {heroHeader}

        {heroSubcopy && (
          <p className={styles.heroSubcopy} data-testid="hero-subcopy">
            {heroSubcopy}
          </p>
        )}

        {withBenefitsList && (
          <HeroBenefitsSection heroBenefits={heroBenefits} />
        )}

        <HeroFormSection
          buttonText={heroCtaText}
          buttonLink={heroCtaLink}
          placeholder={heroEmailPlaceholder}
        />

        <HeroCcText heroCcText={heroCcText} className={styles.heroCcText} />
        <ReviewsHomeAB alter={false} />
      </div>

      {heroAsset}
    </HeroWrapper>
  );
};

export default MainHeroContainer;
