import React from "react";
import { graphql, PageProps } from "gatsby";
import SEO from "gatsby-plugin-wpgraphql-seo";

import B2BGeneralContainer from "../../containers/B2BContainer/B2BGeneralContainer";
import GeneralPpcLayout from "../../layouts/GeneralPpcLayout";
import GeneralPpcSliderAndCtaContainer from "../../containers/GeneralPpcSliderAndCtaContainer";
import MainHeroContainer from "../../containers/MainHeroContainer";
import PartnersGridContainer from "../../containers/PartnersContainers/PartnersGridContainer/PartnersGridContainer";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import StickBottomContent from "../../components/shared/StickBottomContent";
import {
  GeneralPpcBottomCtaType,
  GlobalFooterLargeCtaHType,
  MainHeroType,
  PartneredWithLogosPType,
  SliderType,
  SmartDisplayType,
  TopNavigationCtaType,
  YoastSEOType,
} from "@types";

import styles from "./gPpcStyles.module.scss";
import { deIndexSeo } from "../../utils/deIndexSeo";

type GeneralPpcLandingPageType = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      generalPpcBottomCta: GeneralPpcBottomCtaType;
      mainHero: MainHeroType;
      partnersLogos: PartneredWithLogosPType;
      slider: SliderType;
      smartDisplay: SmartDisplayType;
      topNavigationCta: TopNavigationCtaType;
    };
  };
};

const GeneralPpcLandingPage: React.FC<PageProps<GeneralPpcLandingPageType>> = ({
  data,
}) => {
  const {
    wpPage: {
      seo,
      template: {
        generalPpcBottomCta,
        smartDisplay: { smartDisplayToggle },
        topNavigationCta: { ctaLink, ctaText },
        mainHero,
        partnersLogos,
        slider,
      },
    },
  } = data;

  const heroWithImageData: MainHeroType = {
    ...mainHero,
    isAnimation: false,
    heroAnimation: null!,
  };

  const globalFooterLargeH: GlobalFooterLargeCtaHType = {
    navCtaLink: ctaLink,
    navCtaText: ctaText,
    smartDisplayToggle,
    ctaBannerLargeCta: "",
    ctaBannerLargeCtaText: "",
    ctaBannerLargeSubcopy: "",
    ctaBannerLargeTitle: "",
    ctaBannerLargeBackgroundImage: {
      altText: "",
      localFile: undefined,
    },
    ctaBannerLargeList: [],
  };

  return (
    <GeneralPpcLayout globalFooterLargeH={globalFooterLargeH}>
      {seo && (
        <SEO post={deIndexSeo(data.wpPage as unknown as Queries.WpPage)} />
      )}

      <MainHeroContainer
        heroData={heroWithImageData}
        withTypewriterAnimation
        key={"var-0"}
      />

      <PartnersGridContainer
        className={styles.partnersSlider}
        props={partnersLogos}
      />

      <B2BGeneralContainer className={styles.bookingToBilling} />

      <GeneralPpcSliderAndCtaContainer
        slider={slider}
        generalPpcBottomCta={generalPpcBottomCta}
      />

      <StickBottomContent>
        <PrimaryButton buttonText="Start for free" buttonLink="/#" />
      </StickBottomContent>
    </GeneralPpcLayout>
  );
};

export default GeneralPpcLandingPage;

export const query = graphql`
  query GeneralPpcLandingPageQuery($id: String) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpGeneralPPCPageTemplate {
          ...GeneralPpcBottomCtaFragment
          ...MainHeroFragment
          ...PartnersLogosFragment
          ...SliderFragment
          ...SmartDisplayFragment
          ...TopNavigationCtaFragment
        }
      }
    }
  }
`;
